// Override default variables before the import
@import "./scss/variable.scss";

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

// Variables ===================================================================

$font-family-serif: Georgia, Cambria, "Times New Roman", Times, serif !default;
$web-font-path: "https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700&display=swap" !default;
@import url($web-font-path);

// basics

.pull-right {
  float: right;
}

.btn-link {
  padding: 0;
}

// // Typography ==================================================================

p {
  font-family: $font-family-serif;
}

blockquote {
  font-style: italic;
}

footer {
  font-size: $font-size-sm;
}

.lead {
  color: $gray-600;
  font-family: $font-family-sans-serif;
}

// Tables ======================================================================

table,
.table {
  font-size: $font-size-sm;

  &-primary,
  &-secondary,
  &-success,
  &-info,
  &-warning,
  &-danger {
    color: #fff;
  }
}

.table {
  &-primary {
    &,
    > th,
    > td {
      background-color: $primary;
    }
  }

  &-secondary {
    &,
    > th,
    > td {
      background-color: $secondary;
    }
  }

  &-light {
    &,
    > th,
    > td {
      background-color: $light;
    }
  }

  &-dark {
    &,
    > th,
    > td {
      background-color: $dark;
    }
  }

  &-success {
    &,
    > th,
    > td {
      background-color: $success;
    }
  }

  &-info {
    &,
    > th,
    > td {
      background-color: $info;
    }
  }

  &-danger {
    &,
    > th,
    > td {
      background-color: $danger;
    }
  }

  &-warning {
    &,
    > th,
    > td {
      background-color: $warning;
    }
  }

  &-active {
    &,
    > th,
    > td {
      background-color: $table-active-bg;
    }
  }

  &-hover {
    .table-primary:hover {
      &,
      > th,
      > td {
        background-color: darken($primary, 5%);
      }
    }

    .table-secondary:hover {
      &,
      > th,
      > td {
        background-color: darken($secondary, 5%);
      }
    }

    .table-light:hover {
      &,
      > th,
      > td {
        background-color: darken($light, 5%);
      }
    }

    .table-dark:hover {
      &,
      > th,
      > td {
        background-color: darken($dark, 5%);
      }
    }

    .table-success:hover {
      &,
      > th,
      > td {
        background-color: darken($success, 5%);
      }
    }

    .table-info:hover {
      &,
      > th,
      > td {
        background-color: darken($info, 5%);
      }
    }

    .table-danger:hover {
      &,
      > th,
      > td {
        background-color: darken($danger, 5%);
      }
    }

    .table-warning:hover {
      &,
      > th,
      > td {
        background-color: darken($warning, 5%);
      }
    }

    .table-active:hover {
      &,
      > th,
      > td {
        background-color: $table-active-bg;
      }
    }
  }
}

// // Forms =======================================================================
// // Indicators ==================================================================

.alert {
  color: $white;
  font-size: $font-size-sm;

  &,
  p {
    font-family: $font-family-sans-serif;
  }

  a,
  .alert-link {
    color: #fff;
    font-weight: normal;
    text-decoration: underline;
  }

  @each $color, $value in $theme-colors {
    &-#{$color} {
      @if $enable-gradients {
        background: $value linear-gradient(0deg, mix($body-bg, $value, 15%), $value) repeat-x;
      } @else {
        background-color: $value;
      }
    }
  }

  &-light {
    &,
    & a,
    & .alert-link {
      color: $body-color;
    }
  }
}

#alert-bar {
  top: calc(#{$navbar-brand-height} + 40px);
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.badge {
  vertical-align: bottom;
}

// Navbar ======================================================================

// Buttons =====================================================================

// Typography ==================================================================

body {
  -webkit-font-smoothing: antialiased;
}

// Tables ======================================================================

// Forms =======================================================================

// Navs ========================================================================

// Indicators ==================================================================

// Progress bars ===============================================================

.progress {
  @include box-shadow(none);

  .progress-bar {
    font-size: 8px;
    line-height: 8px;
  }
}

// Containers ==================================================================

// Progress bars ===============================================================

// Containers ==================================================================

.list-group {
  font-size: $font-size-sm;
}

.padded-top {
  padding-top: 80px;
}

//  graprejs related
/* Let's highlight canvas boundaries */
#gjs {
  border: 3px solid #444;
  height: 80vh;
  width: 70%;
  float: left;
}
.full-height {
  height: 90vh;
}

/* Toast notifications */
.react-toast-notifications__container {
  margin-top: calc(#{$navbar-brand-height} + 40px);
}

.pl-table {
  .row {
    align-items: center;
  }
  .col {
    padding: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #dee2e6;
    height: 40px;
  }
  .pl-thead {
    font-weight: bold;
  }
  .pl-tbody {
    .col {
      border: 1px solid #dee2e6;
      height: 40px;
    }
  }
}

.react-datepicker-wrapper {
  display: block !important;
}

.modal-container {
  .modal-body {
    max-height: 70vh;
    // min-height: 50vh;
    overflow: auto;
  }
}
.modal-container {
  .template-preview-body {
    max-height: 70vh;
    min-height: 50vh;
    overflow: auto;
  }
}

.react-toast-notifications__toast__content {
  overflow: hidden;
}
